import { merge } from 'lodash'
import { saveToSessionStorage } from '../../utils/sessionStorage'
export const AUTH_KEY = 'auth'

const actions = {}
const initialState = {
  tokens: {
    auth: null,
  },
  userData: null,
  currentUser: null,
  currentVendor: null,
  contractData: null,
  userPermissions: {
    canManageUsers: false,
    canMakePayments: false,
    canManagePaymentMethods: false,
    canUpdateCompanySettings: false,
    canOptOutSystemNotifications: false,
  },
  isAnnouncementBannerOpen: true,
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

// User sign in
export const APPLY_USER_SIGN_IN = `${AUTH_KEY}/APPLY_USER_SIGN_IN`

actions[APPLY_USER_SIGN_IN] = (state, { payload }) => {
  saveToSessionStorage('contractSlug', payload.contractSlug)
  return {
    ...state,
    tokens: state?.tokens
      ? { ...state?.tokens, [payload.contractSlug]: payload.userAuth }
      : { [payload.contractSlug]: payload.userAuth },
    currentUser: payload.currentUser,
    contractData: null,
  }
}

// User sign out
export const APPLY_USER_SIGN_OUT = `${AUTH_KEY}/APPLY_USER_SIGN_OUT`

actions[APPLY_USER_SIGN_OUT] = (state) =>
  merge({}, state, {
    tokens: null,
    userData: null,
    currentUser: null,
    contractData: null,
    userPermissions: {
      canManageUsers: false,
      canMakePayments: false,
      canManagePaymentMethods: false,
      canUpdateCompanySettings: false,
      canOptOutSystemNotifications: false,
    },
    isAnnouncementBannerOpen: true,
  })

// Current user update
export const APPLY_CURRENT_USER_UPDATE = `${AUTH_KEY}/APPLY_CURRENT_USER_UPDATE`

actions[APPLY_CURRENT_USER_UPDATE] = (state, { payload }) =>
  merge({}, state, {
    currentUser: payload.currentUser,
  })

// Set contract data
export const SET_CONTRACT_DATA = `${AUTH_KEY}/SET_CONTRACT_DATA`

actions[SET_CONTRACT_DATA] = (state, { payload }) =>
  merge({}, state, {
    contractData: payload,
  })

// Set user data
export const SET_USER_DATA = `${AUTH_KEY}/SET_USER_DATA`

actions[SET_USER_DATA] = (state, { payload }) =>
  merge({}, state, {
    userData: payload,
  })

// User permissions update
export const UPDATE_USER_PERMISSIONS = `${AUTH_KEY}/UPDATE_USER_PERMISSIONS`

actions[UPDATE_USER_PERMISSIONS] = (state, { payload }) =>
  merge({}, state, {
    userPermissions: payload,
  })

// User membership data update
export const UPDATE_USER_MEMBERSHIPS = `${AUTH_KEY}/UPDATE_USER_MEMBERSHIP`

actions[UPDATE_USER_MEMBERSHIPS] = (state, { payload }) =>
  merge({}, state, {
    userMemberships: payload,
  })

// Set vendor data
export const SET_VENDOR_DATA = 'SET_VENDOR_DATA'

actions[SET_VENDOR_DATA] = (state, { payload }) =>
  merge({}, state, {
    currentVendor: payload,
  })

// Set vendor data
export const CLOSE_ANNOUNCEMENT_BANNER = `${AUTH_KEY}/CLOSE_ANNOUNCEMENT_BANNER`

actions[CLOSE_ANNOUNCEMENT_BANNER] = (state) =>
  merge({}, state, {
    isAnnouncementBannerOpen: false,
  })
